<template>
  <b10-base>
    <v-radio-group
      v-model="form.tipo_quitar"
      :rules="formRules.tipo_quitar"
    >
      <v-radio
        :value="TIPO_QUITAR_MATERIAL_AFECTADO_PARTE.quitarParte.id"
      >
        <template #label>
          <div>
            {{ TIPO_QUITAR_MATERIAL_AFECTADO_PARTE.quitarParte.descripcion }}<br>
            <small>Se revisará en otro parte</small>
          </div>
        </template>
      </v-radio>
      <v-radio
        :value="TIPO_QUITAR_MATERIAL_AFECTADO_PARTE.quitarParteOT.id"
      >
        <template #label>
          <div>
            {{ TIPO_QUITAR_MATERIAL_AFECTADO_PARTE.quitarParteOT.descripcion }}<br>
            <small>No se va a revisar en esta ocasión</small>
          </div>
        </template>
      </v-radio>
      <v-radio
        v-if="hasPermBorrarMaterialInstalado"
        :value="TIPO_QUITAR_MATERIAL_AFECTADO_PARTE.quitarParteOTSistema.id"
      >
        <template #label>
          <div>
            {{ TIPO_QUITAR_MATERIAL_AFECTADO_PARTE.quitarParteOTSistema.descripcion }}<br>
            <small>Es un error, el material no existe</small>
          </div>
        </template>
      </v-radio>
    </v-radio-group>
  </b10-base>
</template>

<script>
import formMixin from '@/mixins/formMixin'
import { TIPO_QUITAR_MATERIAL_AFECTADO_PARTE } from '@/utils/consts'

export default {
  mixins: [formMixin],
  props: {
    hasPermBorrarMaterialInstalado: {
      type: Boolean,
      default: false,
    },
  },
  data () {
    return {
      form: {
        tipo_quitar: TIPO_QUITAR_MATERIAL_AFECTADO_PARTE.quitarParte.id,
      },
      formRules: {
        tipo_quitar: [
          v => !!v || 'Campo requerido'
        ],
      },
      TIPO_QUITAR_MATERIAL_AFECTADO_PARTE,
    }
  },
  async created () {
    await this.loadForm()
  },
  methods: {
    async loadFormData () {
      // no tiene posibilidad de edicion
    },
  }
}
</script>
